import React from "react"
import styled from "styled-components"
import Grid from "../components/Grid"
import { graphql } from "gatsby"
import Seo from "../components/Seo"

const Container = styled.div``
const Title = styled.h1``
const Body = styled.div``

const page = ({ data }) => {
  const { heading, body } = data.prismicPage.data

  return (
    <div>
      <Container>
        {heading && <Title>{heading.text}</Title>}
        {body && <Body dangerouslySetInnerHTML={{ __html: body.html }} />}
      </Container>
      <Seo />
    </div>
  )
}

export const query = graphql`
  query($locale: String, $id: String) {
    prismicPage(prismicId: { eq: $id }, lang: { eq: $locale }) {
      data {
        heading {
          text
        }
        body {
          html
        }
      }
    }
  }
`

export default page
