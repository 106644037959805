import React from "react"
import Helmet from "react-helmet"
import { useSettingContext } from "../settingsContext"
import get from "lodash/get"
import { graphql, useStaticQuery } from "gatsby"

const Seo = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicSeo {
        nodes {
          id
          lang
          data {
            title {
              text
            }
            image {
              url
            }
            description {
              text
            }
          }
        }
      }
    }
  `)
  const t = useSettingContext()
  const node = data.allPrismicSeo.nodes.find(n => n.lang === t.locale)
  const title = get(node, "data.title.text")
  const description = get(node, "data.description.text")
  const image = get(node, "data.image.url")
  return (
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{title}</title>
      <meta property="og:type" content="website" />
      <meta name="description" content={description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />

      <meta property="og:image" content={image} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="jip" />
      <meta name="image" content={image} />
      <html lang={t.locale} />

      {/* <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-43885727-5"
        /> */}
    </Helmet>
  )
}

export default Seo
